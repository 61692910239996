<template>
  <div class="error-page">
    <img src="@/assets/error-page.jpg" alt="" />
    <el-button
      class="btn"
      type="primary"
      size="small"
      @click="$router.push({ name: 'Home' })"
      >回到首页</el-button
    >
  </div>
</template>

<script>
export default {
  name: "Error",
};
</script>

<style lang="scss" scoped>
@media only screen and (min-width: 769px) {
  .error-page {
    width: 100%;
    position: relative;
    img {
      width: 100%;
      height: auto;
      vertical-align: middle;
    }
    .btn {
      position: absolute;
      left: 2%;
      bottom: 1%;
      padding-left: 22px;
      padding-right: 22px;
    }
  }
}
@media only screen and (max-width: 768px) {
  .error-page {
    height: calc(100vh - 60px - 70px);
    position: relative;
    img {
      width: 100%;
      height: 100%;
      vertical-align: middle;
    }
    .btn {
      position: absolute;
      left: 2%;
      bottom: 1%;
      padding-left: 22px;
      padding-right: 22px;
    }
  }
}
</style>